import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import VueI18n from 'vue-i18n'
import VueSimpleMarkdown from 'vue-simple-markdown'
import enStrings from '@/assets/translations/en.i18n.json'
import koStrings from '@/assets/translations/ko.i18n.json'
import jpStrings from '@/assets/translations/jp.i18n.json'
import router from './router'
import axios from 'axios'

var lang = window.navigator.userLanguage || window.navigator.language
var locale = 'en'
if (lang === 'ja' || lang === 'ja-JP') locale = 'jp'
if (lang === 'ko' || lang === 'ko-KR') locale = 'ko'
Vue.prototype.$lang = locale
Vue.prototype.$axios = axios

Vue.config.productionTip = false
Vue.use(VueI18n)
Vue.use(VueMeta)
Vue.use(VueSimpleMarkdown)
const messages = { en: enStrings, ko: koStrings, jp: jpStrings }
const i18n = new VueI18n({
  locale: locale,
  messages,
})

new Vue({
  i18n,
  router,
  render: (h) => h(App),
}).$mount('#app')
