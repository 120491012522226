var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative flex flex-column",attrs:{"id":"app"}},[_c('header',{staticClass:"header flex caps items-center pl2 pr2 clearfix justify-center"},[_c('div',{staticClass:"col col-12 sm-col-10 lg-col-7 flex items-center tall"},[_c('router-link',{staticClass:"header__img-container",attrs:{"to":{ name: 'top' }}},[_c('img',{staticClass:"header__img",attrs:{"src":require("@/assets/logo-icon.png")}})]),_c('div',{staticClass:"header__menu flex items-center header__menu--saph",class:{
          'header__menu--selected': _vm.selected === 'sapphire' || _vm.selected === 'top',
        },on:{"click":() => {
            _vm.menuSelect('sapphire')
          }}},[_vm._v(" Sapphire ")]),_c('div',{staticClass:"header__menu flex items-center",class:{ 'header__menu--selected': _vm.selected === 'scarlet' },on:{"click":() => {
            _vm.menuSelect('scarlet')
          }}},[_vm._v(" Scarlet ")]),_c('div',{staticClass:"header__menu header__menu--music flex items-center",class:{ 'header__menu--selected': _vm.selected === 'music' },on:{"click":() => {
            _vm.menuSelect('music')
          }}},[_vm._v(" Music ")])],1)]),_c('div',{staticClass:"app__content flex-auto"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view')],1)],1),_c('Footer',{staticClass:"app__footer relative",class:{ 'app__footer--no-bg': _vm.selected === 'updates' }})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }